/*
  Notification main scripts
*/

;(function () {
	'use strict';

  var notificationObj = {
    cookieDays : 7,
    close : function (e) {
      $(e).parent().addClass('minimize-notif');
      this.setNotificationCookie('notif-minimized');
    },
    open : function (e) {
      if ($(e).parent().hasClass('minimize-notif')) $(e).parent().removeClass('minimize-notif');
      this.setNotificationCookie('notif-standard');
    },
    setNotificationCookie : function(value){
      var expires = new Date();
      expires.setTime(expires.getTime() + (this.cookieDays * 24 * 60 * 60 * 1000));
      document.cookie =  'notifCookie=' + value + ';expires=' + expires.toUTCString();
    },
    getNotificationCookie : function(){
      var keyValue = document.cookie.match('(^|;) ?notifCookie=([^;]*)(;|$)');
      return keyValue ? keyValue[2] : null;
    }
  };



  // Next ul toggle functions
	$('.notification-close').on('click', function(){
    notificationObj.close(this);
		return false;
  });
  // Next ul toggle functions
	$('.notification-open').on('click', function(){
    notificationObj.open(this);
		return false;
	});

}());