/*
  Application main scripts
*/

;(function () {
	'use strict';

  // If we are on location item page / location list present
	if ($('.locations').length > 0){

        // Hover on instructor
        $('.location-item').hover(function(){
        $(this).children('.inner').children('.data').slideDown();
        },
        function(){
        $(this).children('.inner').children('.data').slideUp();
        });

        // Click on more
        $('.location-more').click(function(){
          // console.log('descr');
          $(this).parents('.inner').children('.description').fadeToggle();
        });

        // if we have the dancetypes
        if ($('.location-dancetype-filter').length > 0){
          console.log('location-dancetype-filter');
          $('.location-dancetype-filter').children('.dancetype').on('click', function(){
            var filterElement = $(this).attr('data-orarend-filter');
            console.log('fock', filterElement);
            window.orarendTable.search(filterElement).draw();
          });
        }


      }

  // If we are on location page
	if ($('.locationsMap').length > 0){

    // Open/Close locations1
    $('.location-item').on('click', '.fa-chevron-down', function(){
      $('.location-item').removeClass('opened');
      $('.fa-chevron-up').addClass('fa-chevron-down').removeClass('fa-chevron-up');

      $(this).closest('.location-item').addClass('opened');
      $(this).addClass('fa-chevron-up').removeClass('fa-chevron-down');
    });

    $('.location-item').on('click', '.fa-chevron-up', function(){
      $('.location-item').removeClass('opened');
      $(this).addClass('fa-chevron-down').removeClass('fa-chevron-up');
    });

    // Contact form
    var contactForm = $("#contactForm").validate({
      rules: {
        fullname: {
          required: true,
          minlength: 5
        },
        email: {
          required: true,
          email: true
        },
        telephone: {
          required: true,
          minlength: 5
        },
        comment: {
          required: true,
          minlength: 10
        }
      },
      messages: {
        fullname: {
          required: "Kérlek add meg a neved",
          minlength: "A név minimum 5 betű kell hogy legyen"
        },
        email: {
          required: "Kérlek add meg az email címed",
          email: "Az email cím hibásnak tűnik, kérlek ellenőrizd"
        },
        telephone: {
          required: "Kérlek add meg a telefonszámod"
        },
        comment: {
          required: "Kérlek írd meg az üzeneted",
          minlength: "Az üzenet minimum 10 betű kell hogy legyen"
        }
      },
      submitHandler: function(form) {

        $('.sendContactForm').prepend('<i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="font-size:20px;"></i> ');

        $.post( "/mail/send/contact", $( "#contactForm" ).serialize(), function(data) {

          if (data.status == 1){
            $(".formfeedback").html('<div class="alert alert-success alert-dismissable"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a><strong>Siker!</strong> '+data.payload+'</div>');
          }
          if (data.status == 0){
            $(".formfeedback").html('<div class="alert alert-danger alert-dismissable"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a><strong>Hiba!</strong> '+data.payload+'</div>');
          }
          $('#contactForm').find("input[type=text], textarea").val("");
          $('.sendContactForm').html('Üzenet elküldése');

        })
        .fail(function() {
          console.log( "API call failed!" );
        });


        return false;
      }
    });

  }


}());

// Google maps markers
var gmarkers=[];

function initMap(){
    // Maps API: Goldance locations: AIzaSyASFxQJaSUT2BjacD_QKcXrfmfweIB620c
    // location variable from location_page_blade.php
    if ($('.locationsMap').length > 0){
      var map = new google.maps.Map(document.getElementById('locationsMap'), {
        zoom: 10,
        scrollwheel: 0,
        center: new google.maps.LatLng(47.4965538,19.0547144),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      var infowindow = new google.maps.InfoWindow();

      var marker, i;

      for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(locations[i][1], locations[i][2]),
          map: map
        });
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
          return function() {
            infowindow.setContent(locations[i][0]);
            infowindow.open(map, marker);
          }
        })(marker, i));

        // Add markers to array in global scope
        // gmarkers.push(marker);
      }

      // console.log('markers:', gmarkers);

      google.maps.event.addListener(map, 'tilesloaded', function(evt) {
        $('#locationsMapLoader').fadeOut(function(){
          $('#locationsMap').css('visibility', 'visible');
        });
      });
    }
}