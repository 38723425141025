/*
  Application main scripts
*/

;(function () {
	'use strict';


  // Dancepage Gallery
  // ---------------------------------------------------------------------------
  if($('#dancepage-gallery').length !== 0) {
    $('#dancepage-gallery').lightGallery({
      selector: '.btn_play'
   });
  }


}());