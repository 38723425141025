/*
  Application main scripts
*/

function smoothScrollTo(target, floatHeaderHeight){
  $('html, body').animate({
    scrollTop: target.offset().top-floatHeaderHeight
  }, 1000, function() {
    // Callback after animation
    // Must change focus!
    var $target = $(target);
    $target.focus();
    if ($target.is(":focus")) { // Checking if the target was focused
      return false;
    } else {
      $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
      $target.focus(); // Set focus again
    };
  });
}

;(function () {
	'use strict';



  // Scroll to top if at least one page height;
  $("body").append('<a href="#top" class="btn-to-top"><i class="fa fa-caret-square-o-up" aria-hidden="true"></i></a>');
  var tapOffset = $("#top").offset().top;
  var topMaxOffset = $("#top").offset().bottom;
  var bodyOffset = $("body").offset().top;

  $(window).bind("scroll", function() {
    var offset = $(this).scrollTop();
      if ( offset >= 800 && !($(".site-top").hasClass('show')) ) {
        $(".btn-to-top").fadeIn().addClass('show');
      }
      if ( offset < 800 ) {
        $(".btn-to-top").fadeOut().removeClass('show');
      }
  });

  // Floating social links
  setTimeout(function () {
    $( ".social-item" ).each(function(i) {
      var $socialItem = $(this);
      setTimeout(function () {
        $( $socialItem ).addClass( "loaded" );
      }, 100 * (i + 1));

    });
  }, 1);

  // Try clicking on phone after working hours
  $('body').on('click', '.company-phone', function(){
    if (moment().day() == '0' || moment().day() == '6' || moment().hour() < '9' || moment().hour() > '20' ){
      $('.company-phone').popover({
        "content" : "Kérjük hívj munkanapokon 09.00 – 20.00 között. Előre is köszönjük.",
        "placement" : "bottom",
        "trigger" : "focus"
      });
      $('.company-phone').popover('show');
      return false;
    }
	});

  // Floating site-top part
  $(window).bind("scroll", function() {
      var offset = $(this).scrollTop();

      if (offset >= bodyOffset && !($(".site-top").hasClass('scrolled'))) {
        $(".site-top").addClass('scrolled')
      }
      else if (offset <= bodyOffset) {
        $(".site-top").removeClass('scrolled')
      }
  });

  // Smooth scroll
  // Select all links with hashes
  var floatHeaderHeight = 100;

  if (location.hash) {
    smoothScrollTo($(location.hash), floatHeaderHeight);
    scrollnow();
  }

  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        smoothScrollTo(target, floatHeaderHeight);
      }
    }
  });


	// Navigation hero
	$('.dance-type').hover(function(){
		var danceType = $(this).attr('class').split(' ')[1];
		$('.dance-selector').removeClass().addClass('dance-selector');
		$('.dance-selector').addClass(danceType);
		$('.hero').removeClass().addClass('hero');
		$('.hero').addClass(danceType);
	});


	$('body').on('click', '.more-btn-with-sepa-close', function(){
		$(this).parent().slideUp();
		$('.more-btn-with-sepa').fadeIn();
		$(this).remove();
		return false;
	});

	// TOP Navigation open/close handler
	$('.menubars').on('click', function(){
		// Put in extra dom element for shade
		var popupNavShade = '<div class="popup-navigation-page-shade"></div>';
		$('body').prepend(popupNavShade);
		$('.popup-navigation-page-shade').fadeIn(function(){
      $('.popup-nav-container').show();
			$('.popup-navigation').slideDown();
		});
		return false;
	});

	$('.popup-navigation-close').on('click', function(){
		$('.popup-navigation').slideUp(function(){
      $('.popup-navigation-page-shade').fadeOut(function(){
        $('.popup-navigation-page-shade').remove();
        $('.popup-nav-container').hide();
      });
    });
    return false;
  });

  // Next ul toggle functions
	$('.toggle-next-ul').on('click', function(){
    $(this).parent().next().slideToggle();
    if ($(this).hasClass('fa-angle-down')) {
      $(this).removeClass('fa-angle-down').addClass('fa-angle-up');
    }else{
      $(this).removeClass('fa-angle-up').addClass('fa-angle-down');
    }

		return false;
	});

}());