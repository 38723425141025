/*
  Application main scripts
*/

document.addEventListener("DOMContentLoaded", yall);

window.isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

;(function () {
	'use strict';


	// More content spec
	$('.more-btn-with-sepa').click(function(){
		var closeBtn = '<div class="more-btn-with-sepa-close">' +
										'<hr class="separator">' +
										'<a href="#" class="btn btn-dashed"> <i class="fa fa-angle-up" aria-hidden="true"></i> vissza </a>' +
									'</div>';

		$(this).fadeOut();
		$(this).parent().next('.more-content').append(closeBtn);
		$(this).parent().next('.more-content').slideDown();

		return false;
	});

  // Timepicker
  if($('.timepicker').length !== 0) {
    $('.timepicker').timepicker({
      timeFormat: 'HH.mm',
      interval: 30,
      minTime: '10',
      maxTime: '10:00pm',
      defaultTime: '',
      startTime: '10:00',
      dynamic: false,
      dropdown: true,
      scrollbar: true
    });
  }
	// Select styles - Selectric
  //$('select').selectric();

  // General Hover Image
  $('img[data-hover]').on({
    'mouseenter': function(){

      var oldsrc = $(this).attr('src');
      var newsrc = $(this).attr('data-hover');
      $(this).attr('data-hover', oldsrc);
      $(this).attr('src', newsrc);

    },
    'mouseleave': function(){
      var oldsrc = $(this).attr('src');
      var newsrc = $(this).attr('data-hover');
      $(this).attr('src', newsrc);
      $(this).attr('data-hover', oldsrc);
    }
  });

	// FAQ
	$('.open-faq').on('click', function(){

		if ($(this).hasClass('opened')) {
			$(this).removeClass('opened');
			$(this).children('i').removeClass('fa-toggle-up');
			$(this).children('i').addClass('fa-toggle-down');
		}else{
			$(this).addClass('opened');
			$(this).children('i').removeClass('fa-toggle-down');
			$(this).children('i').addClass('fa-toggle-up');
		}

		$(this).parent().next('.description').slideToggle();

		return false;
	});

	// iPad and iPod detection
	var isiPad = function(){
		return (navigator.platform.indexOf("iPad") != -1);
	};

	var isiPhone = function(){
	    return (
			(navigator.platform.indexOf("iPhone") != -1) ||
			(navigator.platform.indexOf("iPod") != -1)
	    );
	};

  // Gallery
  if($('#goldanceGallery').length !== 0) {
	  $("#goldanceGallery").lightGallery();
  }
	// Main Menu Superfish
	var mainMenu = function() {

	};


  // // Lazy load for bootstrap carousel on mobile
  $('.carousel.lazy-load').bind('slide.bs.carousel', function (e) {
    var image = $(e.relatedTarget).find('img[data-src]');
    image.attr('src', image.data('src'));
    image.removeAttr('data-src');

  });

  var video_wrapper = $('.youtube-embed-wrapper');
  //  Check to see if youtube wrapper exists
  if(video_wrapper.length){
    // If user clicks on the video wrapper load the video.
    $('.play-youtube-video').on('click', function(){
      /* Dynamically inject the iframe on demand of the user.
      Pull the youtube url from the data attribute on the wrapper element. */
      video_wrapper.html('<iframe allowfullscreen frameborder="0" class="embed-responsive-item" style="' + video_wrapper.data('yt-style') + '" src="' + video_wrapper.data('yt-url') + '"></iframe>');
    });
  }

  // Cookie consent
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#343c66",
        "text": "#cfcfe8"
      },
      "button": {
        "background": "#f71559"
      }
    },
    "content": {
      "message": "Hozzájárulok ahhoz, hogy a weboldal a szolgáltatások és a beállítások biztosításához, valamint a honlap látogatottságának figyelemmel kíséréséhez sütiket használ. ",
      "dismiss": "Elfogadom",
      "link": "További információ",
      "href": "https://goldance.hu/adatkezeles"
    }
  });

	// Document on load.
	$(function(){
		mainMenu();
	});


}());