/*
  Application main scripts
*/

;(function () {
	'use strict';


	if ($('.price-calculator').length > 0){

		// Calculator pass content
		if (window.isMobile) {
			$('.card-item').on('click', function(){
				$(this).children('.info').fadeToggle();
			});
		}else{
			$('.card-item').hover(function(){
				$(this).children('.info').fadeToggle();
			})

		}



		// Sliders in PriceCalculator
		// With JQuery
		// data-slider-min="0" data-slider-max="5" data-slider-step="1"
		var sliderOptions = {
			min: "0",
			max: "5",
			value: "0",
			step: "1"
		}

		var zumbaSlider = $('#zumbaSlider').bootstrapSlider(sliderOptions)
			.on('change', function(event){
				setGroupValue('zumba',event.value.newValue);
			});
		var salsaSlider = $('#salsaSlider').bootstrapSlider(sliderOptions)
			.on('change', function(event){
				setGroupValue('salsa',event.value.newValue);
			});
		var tarsasSlider = $('#tarsasSlider').bootstrapSlider(sliderOptions)
			.on('change', function(event){
				setGroupValue('tarsastanc',event.value.newValue);
			});
		// var rockySlider = $('#rockySlider').bootstrapSlider(sliderOptions)
		// 	.on('change', function(event){
		// 		setGroupValue('rocky',event.value.newValue);
		// 	});
		var westcoastSlider = $('#westcoastSlider').bootstrapSlider(sliderOptions)
			.on('change', function(event){
				setGroupValue('westcoast',event.value.newValue);
			});
		var bachataSlider = $('#bachataSlider').bootstrapSlider(sliderOptions)
			.on('change', function(event){
				setGroupValue('bachata',event.value.newValue);
			});

	/* Kalkulator app */
		var tancagObj = {
      'zumba':0,
      'salsa':0,
      'tarsastanc':0,
      // 'rocky':0,
      'westcoast':0,
      'vonalas':0,
      'bachata':0
    }
		var tancagName = {
      'zumba':'Zumba',
      'salsa':'Salsa',
      'tarsastanc':'Társastánc',
      // 'rocky':'Rocky',
      'westcoast':'West Coast Swing',
      'vonalas':'Vonalas Salsa',
      'bachata':'Bachata'
    };

		var berletCost = MONTHLY_PASS;
		var kiegBerletCost = FIRST_ADDITIONAL_PASS;
		var kiegBerletCost2 = SECOND_ADDITIONAL_PASS;
    var alkalom90Cost = OCCASIONAL_90;
    // var alkalom9BerletCost = OCCASION_9_PASS;
    var alkalom9BerletCost = 12000;
		var alkalom60Cost = OCCASIONAL_60;
    // var alkalom6BerletCost = OCCASION_6_PASS;
    var alkalom6BerletCost = 12000;

		function setGroupValue(group, val) {
			tancagObj[group] = val;
			$('.' + group + 'SliderCount').html(val);
			$('.sumAlkalom').html(getAllAlkalom());
		}

		function getAllAlkalom() {
			var allalkalom=0
			$.each(tancagObj, function(index, value) {
				allalkalom = parseInt(allalkalom) + parseInt(value);
			});
			return allalkalom;
		}

		function getAllCsopnum() {
			var allcsop=0
			$.each(tancagObj, function(index, value) {
				if (value != '0') {
					allcsop = parseInt(allcsop) + 1;
				}
			});
			return allcsop;
		}

		function calculateSumCost() {
			var sumcost=0
			$.each(tancagObj, function(index, value) {
				if (index == 'zumba') {
					sumcost =  parseInt(sumcost) + (parseInt(value) * parseInt(alkalom60Cost) );
				}else{
					sumcost =  parseInt(sumcost) + (parseInt(value) * parseInt(alkalom90Cost) );
				}
			});
			return sumcost;
    }

    function zumbaPresent(){
      var result = false;
			$.each(tancagObj, function(index, value) {
				if (index == 'zumba') {
          if (value >= 1) result = true;
				}
      });
      return result;
    }

    function getZumbaAlkalom(){
      var result = 0;
			$.each(tancagObj, function(index, value) {
				if (index == 'zumba') {
          if (value >= 1) result = value;
				}
      });
      return result;
		}

		function calculateDiakKedvezmenyesCost(allCsopNum, sumCost) {
			var result = 0;
			var sumKiegBerletCost = 0;
      var zumbaPresent = false;
      var diakDiscount = 0.20;

			$.each(tancagObj, function(index, value) {
				// if (index == 'zumba' && value != '0') {
				// 	zumbaPresent = true;
				// }
			});

			if (zumbaPresent == true) {
				if (allCsopNum != 1) {
					sumCost = parseInt(sumCost) - (parseInt(sumCost)*diakDiscount);
					if (allCsopNum >= 3) sumKiegBerletCost = parseInt(kiegBerletCost) + parseInt(kiegBerletCost2);
					if (allCsopNum == 2) sumKiegBerletCost = parseInt(kiegBerletCost);
				}else{
					sumCost = parseInt(sumCost);
				}
			}else{
				sumCost = parseInt(sumCost) - (parseInt(sumCost)*diakDiscount);
				if (allCsopNum >= 3) sumKiegBerletCost = parseInt(kiegBerletCost) + parseInt(kiegBerletCost2);
				if (allCsopNum == 2) sumKiegBerletCost = parseInt(kiegBerletCost);
			}

			result = (parseInt(sumCost) + parseInt(sumKiegBerletCost));

			return result;

		}

		$('.alkalom').click(function() {
			$(this).parent('div').children('.alkalom').removeClass('selected');
			$(this).addClass('selected');
			setGroupValue(($(this).attr('group')), $(this).html());
		})

		$('.btn-calculate').click(function() {
			$('.calculatepanel').fadeOut('fast', function(){
        var allAlkalom = 0;
        var allCsopNum = getAllCsopnum();
        var zumbaAlkalom = (getZumbaAlkalom()*4);
				var sumCost = 0;
				var sumKiegBerletCost = 0;
        var sumAlkalomCost = 0;
        var alkalom9DayCost = (parseFloat((parseInt(alkalom9BerletCost))/9)).toFixed();
        var alkalom6DayCost = (parseFloat((parseInt(alkalom6BerletCost))/6)).toFixed();;
        var sum9AlkalomCost = ((alkalom9DayCost * zumbaAlkalom) > 0) ? alkalom9DayCost * (zumbaAlkalom) : 0 ;
        var sum6AlkalomCost = ((alkalom6DayCost * (allAlkalom - zumbaAlkalom)) > 0) ? alkalom6DayCost * (allAlkalom - zumbaAlkalom) : 0 ;
        var result = 0;
        var resultBerletCost = '';
        var resultAlkalomCost = 0;
				var sumDiakKedvezmenyesCost = 0;

				// Calculate allAlkalom
				if (getAllAlkalom() == 1 ) allAlkalom = 5;
				else allAlkalom = (getAllAlkalom()*4)+1;

				// --
				if (allCsopNum >= 1) {
					var sumCost = berletCost;
					// Even if there is more the 2 kiegberlet the price is stop at 2
					if (allCsopNum >= 3) sumKiegBerletCost = parseInt(kiegBerletCost) + parseInt(kiegBerletCost2);
					if (allCsopNum == 2) sumKiegBerletCost = parseInt(kiegBerletCost);

					//sumAlkalomCost = parseInt(allAlkalom) * parseInt(alkalom90Cost);
					var sumAlkalomCost = calculateSumCost();

          // Different result if only one lesson is selected
          // console.log('allCsopNum: ', allCsopNum);
          // console.log('allAlkalom:', allAlkalom);
          // console.log('zumbaPresent:', zumbaPresent());
          // console.log('zumbaAlkalom:', zumbaAlkalom);
          // console.log('alkalomPrice:', alkalom9DayCost, ' - ', alkalom6DayCost);
          // console.log('alkalomSumPrice:', sum9AlkalomCost, ' - ', sum6AlkalomCost);

          // Fix alkalmas bérletárak összesítő
          var sum69AlkalomCost = parseInt(sum9AlkalomCost) + parseInt(sum6AlkalomCost);
          var sumStyle = '';
          var sum69Style = '';
          if (sum69AlkalomCost > (parseInt(sumCost) + parseInt(sumKiegBerletCost))){
            sumStyle = 'style="font-weight:bold; color:#BF4D43; font-size: 16px;"';
          }else{
            sum69Style = 'style="font-weight:bold; color:#BF4D43; font-size: 16px;"';
          }

          if (allAlkalom == 4) {
            resultBerletCost = '';
            resultAlkalomCost = (parseFloat((parseInt(sumCost) + parseInt(sumKiegBerletCost))/allAlkalom)).toFixed();
            //20% kedvezmeny
            sumDiakKedvezmenyesCost = calculateDiakKedvezmenyesCost(allCsopNum, sumCost);
          }
          if (allAlkalom == 4 && allCsopNum == 1 && zumbaPresent()) {
            resultBerletCost = '';
            resultAlkalomCost = alkalom60Cost;
            console.log('alkalom60Cost ', alkalom60Cost);
            //20% kedvezmeny
            sumDiakKedvezmenyesCost = calculateDiakKedvezmenyesCost(allCsopNum, (sumAlkalomCost)*4 );
          }
          // if (allAlkalom > 4 ){
            // resultBerletCost = '<b class="resulttitle">Összesen:</b> <b class="resultcalculation">'+ (parseInt(sumCost) + parseInt(sumKiegBerletCost)) + ' Ft</b><br>' ;
            resultBerletCost = '<span class="resultinfo font-weight-bold" ' +sumStyle+ '>Korlátlan havi bérlet összesen:</span> <span ' +sumStyle+ '>'+ (parseInt(sumCost) + parseInt(sumKiegBerletCost)) + ' Ft</span><br>' ;
            resultAlkalomCost = (parseFloat((parseInt(sumCost) + parseInt(sumKiegBerletCost))/allAlkalom)).toFixed();
            //20% kedvezmeny
            sumDiakKedvezmenyesCost = calculateDiakKedvezmenyesCost(allCsopNum, sumCost);
          // }

          result = '<span id="calculate-result" class="subtitle">Eredmény:</span><br><br>';
            result += '<p class="resulttext">';
								var i=0;

								for (var element in tancagObj) {
									if ( tancagObj[element] > 0 ) {
										result += '<b>'+tancagName[element]+'</b> heti <b>'+tancagObj[element]+'</b> alkalommal.<br>';
										i++;
									}
								}

								result += '<span class="resultinfo">Ez legalább havi <b>' + allAlkalom + '</b> alkalmat jelent. </span>' +
								'<hr>' +
								resultBerletCost +
                'Egy alkalom korlátlan havi bérlettel: ' + resultAlkalomCost + ' Ft<br>' +
                '<br>';

								// TODO: clear 6 és 9 alkalmas részek
                // if ( allAlkalom  > zumbaAlkalom && zumbaAlkalom !== 0) {
                //   result += '<span ' +sum69Style+ '>6 és 9 alkalmas bérlettel összesen: ' + sum69AlkalomCost + ' Ft</span><br>' +
                //   'Egy táncóra 6 alkalmas bérlettel: ' + alkalom6DayCost + ' Ft<br>' +
                //   'Egy zumba óra 9 alkalmas bérlettel: ' + alkalom9DayCost + ' Ft<br>';
                // }
                // if ( allAlkalom  == zumbaAlkalom) {
                //   result += '<span ' +sum69Style+ '>9 alkalmas bérlettel összesen: ' + sum69AlkalomCost + ' Ft</span><br>' +
                //   'Egy zumba óra 9 alkalmas bérlettel: ' + alkalom9DayCost + ' Ft<br>';
                // }
                // if ( zumbaAlkalom == 0 ) {
                //   result += '<span ' +sum69Style+ '>6 alkalmas bérlettel havi négy alkalom: ' + sum69AlkalomCost + ' Ft</span><br>' +
                //   'Egy táncóra 6 alkalmas bérlettel: ' + alkalom6DayCost + ' Ft<br>';
                // }


                // result += '<br>' +
                // 'Alkalmi díjakkal összesen: ' + (sumAlkalomCost)*4 + ' Ft<br>' +
                // // '<span class="resultinfo">Bérlet nélkül összesen: ' +  + ' Ft (' + allAlkalom + ' alkalom)</span><br>' +
                // 'Alkalmi díj táncórákra: ' + alkalom90Cost + ' Ft<br>' +
                // 'Alkalmi díj zumba órákra: ' + alkalom60Cost + ' Ft<br>' +

								result += '</p>';
								result += '<button class="btn btn-yellow btn-lg btn-fullrow btn-again">Új kalkulálás</button>';
								result += '<p><center><a href="/orarend" class="highlight-dark font-weight-bold">Megnézem az órarendet!</a></center></p>';

          // var resultKedvezmenyes = '<span>Diákkedvezményed esetén -20%:<br>'+ sumDiakKedvezmenyesCost +' Ft<br><small>(Zumbára nem érvényes)</small></span>';
          var resultKedvezmenyes = '<span>Diákkedvezmény esetén -20% <br><br>Az ingyenes első órát követően nincs szükség további online regisztrációra. <b>Bérletet a recepciósunktól vásárolhatsz az órák előtt a helyszínen.</b> Bankkártyás fizetésre nincs lehetőség. SZÉP kártyával a Fáklya klubban, az irodánkban fizethetsz (VI. ker., Csengery utca 68., I. emelet).</span>';

				}else{
					result = '<b style="font-size:30px;" id="calculate-result">0 Ft !!</b><br/><br/> Ennél kedvezményesebb ajánlatunk nincs, bár így mozgással, endorfin termeléssel, formálódással, kikapcsolódással és jó társasággal sem szolgálhatunk.. :)<br/><b style="font-size:20px;">Újra?</b><br/><br/><button class="btn btn-yellow btn-lg btn-fullrow btn-again">Új kalkulálás</button>';
				}

				//console.log(result)
				// Put the results
				$('.calculateresult').html(result);
				$('.calculateresultKedv').html(resultKedvezmenyes);

				$('.calculateresult').fadeIn('slow', function(){
					$('.calculateresultKedv').fadeIn('slow');
        });

        // Scroll to results
        var scrollTo = '#calculate-result';
        setTimeout(function(){
          smoothScrollTo($(scrollTo), 100);
        }, 10);

			});
			return false;
		})

		$('body').on('click', '.btn-again', function () {
			/*
			var tancagObj = {'zumba':0, 'salsa':0, 'tarsastanc':0, 'rocky':0, 'westcoast':0, 'vonalas':0, 'bachata':0}
			var allAlkalom = 0;
			var allCsopNum = 0;
			var sumCost = 0;
			var sumKiegBerletCost = 0;
			var sumAlkalomCost = 0;
			var result = 0;

			zumbaSlider.bootstrapSlider('setValue', 0);
			salsaSlider.bootstrapSlider('setValue', 3);
			tarsasSlider.bootstrapSlider('setValue', 0);
			rockySlider.bootstrapSlider('setValue', 0);
			westcoastSlider.bootstrapSlider('setValue', 0);
			bachataSlider.bootstrapSlider('setValue', 0);
			*/

			$('.calculateresult').fadeOut('slow', function(){
				//after fadeOut fadeIn the result

				$('.calculatepanel').fadeIn('slow', function(){
					/*
					$('.zumbaSliderCount').html('0');
					$('.salsaSliderCount').html('0');
					$('.tarsastancSliderCount').html('0');
					$('.bachataSliderCount').html('0');
					$('.rockySliderCount').html('0');
					$('.westcoastSliderCount').html('0');
					$('.sumAlkalom').html('0');
					*/
				});
				$('.calculateresult').html('');
			});
			$('.calculateresultKedv').fadeOut('slow', function(){
				$('.calculateresultKedv').html('');
			});

			return false;
		});

	}

}());