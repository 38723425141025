/*
  Instructor page sccripts
*/

;(function () {
	'use strict';

	if ($('.instructors').length > 0){
    // Hover on instructor
    $('.instructor-item').hover(function(){
    $(this).children('.inner').children('.data').slideDown();
    },
    function(){
    $(this).children('.inner').children('.data').slideUp();
    });

    // Click on more
    $('.instructor-more').click(function(){
      // console.log('descr');
      $(this).parents('.inner').children('.description').fadeToggle();
    });

  }

	if ($('.dancetypefilter').length > 0){
    // Click on dancetype to filter instructors
    $('.dancetypefilter').click(function(){
      var dancetype = $(this).attr("attr-dancetype");
      var danceName = $(this).attr("attr-dancename");

      $('.instructor-title').html(danceName + ' tanáraink:');

      $('.instructor-item').each(function (element) {
          $(this).fadeOut('fast');
      });

      $('.instructor-item').each(function (element) {
        $(this).fadeOut('fast');
        if ($(this).hasClass(dancetype)){
          $(this).fadeIn('fast');
        }
      });

      $('.clear-filter').fadeIn('slow');

      setTimeout(function(){
        smoothScrollTo($('.instructor-title'), 100);
      }, 10);

      return false;
    });

    $('.clear-filter').click(function(){
      $(this).fadeOut('slow');
      $('.instructor-item').each(function (element) {
        $('.instructor-title').html('Összes:');
        $(this).fadeIn('slow');
    });
    });
	}

}());