/*
  Application main scripts
*/

;(function () {
	'use strict';

  $('[data-toggle="popover"]').popover();

	if ($('.timetableForm').length > 0){
    // Selectric initiate
    var selectricOptions={
      disableOnMobile: false,
      nativeOnMobile: false
    };

    var orarendInfo = $('#orarendSzint').selectric(selectricOptions);
    var orarendNap = $('#orarendNap').selectric(selectricOptions);
    var orarendTanar = $('#orarendTanar').selectric(selectricOptions);
    var orarendKerulet = $('#orarendKerulet').selectric(selectricOptions);
    var orarendcsopSelect = $('#orarendCsop').selectric(selectricOptions);

    // Fill values of Filter elements
    var setSearchFilterValues = {
      'setOrarendSzint': function (selectedCsop, oldValue){
        // Clear szint list
        $("#orarendSzint option").remove();
        // Add options for tancstilus
        $('#orarendSzint').append('<option value="">Szint</option><option value="">Összes</option>');
        $.each(orarendSzint[selectedCsop].level, function(index, value) {
          var appendText;
          if (oldValue === index) {
            appendText = '<option value="'+index+'" selected="selected" >'+value+'</option>';
          }else{
            appendText = '<option value="'+index+'">'+value+'</option>';
          }
          $('#orarendSzint').append(appendText);
        });
        // Refresh szint selectoor
        $("#orarendSzint").selectric('refresh');
      },
      'setOrarendTanar' : function(selectedCsop, oldValue){
        // Clear tanar list
        $("#orarendTanar option").remove();
        // Add options for tanar
        var groupTeachers=[];
        $.each(orarendSzint[selectedCsop].teacher, function(index, value) {
          groupTeachers.push({
            'id': index,
            'nick': value
          });
        });
        groupTeachers = _.sortBy(groupTeachers, ['nick'])

        $('#orarendTanar').append('<option value="">Tanár</option><option value="">Összes</option>');
        $.each(groupTeachers, function(index, value) {
          var appendText;
          if (oldValue === value.id) {
            appendText = '<option value="'+value.id+'" selected="selected" >'+value.nick.nick+'</option>';
          }else{
            appendText = '<option value="'+value.id+'">'+value.nick.nick+'</option>';
          }
          $('#orarendTanar').append(appendText);
        });
        // Refresh tanar selectoor
        $("#orarendTanar").selectric('refresh');
      },
      'setOrarendKerulet' : function(selectedCsop, oldValue){
        // Clear kerulet list
        $("#orarendKerulet option").remove();
        // Add options for tanar
        $('#orarendKerulet').append('<option value="">Kerület</option><option value="">Összes</option>');
        $.each(orarendSzint[selectedCsop].district, function(index, value) {
          var appendText;
          if (oldValue === index) {
            appendText = '<option value="'+index+'" selected="selected" >'+value+'</option>';
          }else{
            appendText = '<option value="'+index+'">'+value+'</option>';
          }
          $('#orarendKerulet').append(appendText);
        });
        // Refresh kerulet selectoor
        $("#orarendKerulet").selectric('refresh');
      },
      'disableAll' : function(){
        $("#orarendKerulet").attr("disabled", "disabled" ).selectric('refresh');
        $("#orarendSzint").attr("disabled", "disabled" ).selectric('refresh');
        $("#orarendTanar").attr("disabled", "disabled" ).selectric('refresh');
        $("#orarendNap").attr("disabled", "disabled" ).selectric('refresh');
        $("#orarendSubmit").attr("disabled", "disabled" );
      },
      'allowAll' : function(){
        $("#orarendKerulet").removeAttr("disabled").selectric('refresh');
        $("#orarendSzint").removeAttr("disabled").selectric('refresh');
        $("#orarendTanar").removeAttr("disabled").selectric('refresh');
        $("#orarendNap").removeAttr("disabled").selectric('refresh');
        $("#orarendSubmit").removeAttr("disabled");
      }
    }



    // Get previous search options (from template var)
    var searchFilterOptions = searchOptions;
    // Fill values if there were previous options

    if (searchFilterOptions.orarendCsop) {

      setSearchFilterValues.setOrarendSzint(
        searchFilterOptions.orarendCsop,
        searchFilterOptions.orarendSzint
      );
      setSearchFilterValues.setOrarendTanar(
        searchFilterOptions.orarendCsop,
        searchFilterOptions.orarendTanar
      );
      setSearchFilterValues.setOrarendKerulet(
        searchFilterOptions.orarendCsop,
        searchFilterOptions.orarendKerulet
      );
      // if there any lets allow submit
      $("#orarendNap").removeAttr("disabled");
      $("#orarendSubmit").removeAttr("disabled");
      setSearchFilterValues.allowAll();
    }


    // Handle select orarendCsop
    // -------------------------------------------------------------------------
    orarendcsopSelect.on('selectric-change', function(event, element, selectric) {
      // Get the selected csoport
      var selectedCsop = $('#orarendCsop').val();

      if (selectedCsop) {
        setSearchFilterValues.setOrarendSzint(selectedCsop);
        setSearchFilterValues.setOrarendTanar(selectedCsop);
        setSearchFilterValues.setOrarendKerulet(selectedCsop);
        setSearchFilterValues.allowAll();
      }else{
        setSearchFilterValues.disableAll();
      }
    });

    setTimeout(function(){
      smoothScrollTo($(scrollTo), 0);
    }, 10);

  }



  // Timetable rowreorder (only if timetable present)
  // ---------------------------------------------------------------------------

  // Init dataTables

  if ($('.noresult').length > 0){
    setTimeout(function(){
      smoothScrollTo($('.noresult'), 100);
    }, 10);
  }
  if ($('.orarend').length > 0){
    var orderby = 0;
    if ( typeof defaultOrder !== 'undefined' ) orderby = defaultOrder;
    // FIXME shouldn't use window here (datatable has to be destroyed when vue app, tableselectorview starts, then rebuild it when finished)
    window.orarendTable = $('#datatable-responsive').DataTable({
      "responsive": false,
      // "responsive": {
      //   details: {
      //     display: $.fn.dataTable.Responsive.display.childRowImmediate,
      //     type: ''
      //   }
      // },
      "paging": false,
      "order": [[ orderby, "asc" ]],
      "columnDefs": [
        {
          // position, #szint, #nap --> hide nosearch
          "targets": [ 0, 1, 4 ],
          "visible": false,
          "searchable": false
        },
        // order szint by #szint
        { "orderData": [ 1 ], "targets": 2 },
        // order nap by #nap
        { "orderData": [ 4 ], "targets": 5 },
        // no ordering for erdekel
        { "orderable": false, "targets": 8 },
      ],
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Hungarian.json"
      },
      //Events
      "initComplete": function(settings, json) {
        console.log('datatables init complete');
        $('.timetable-placeholder').fadeOut(function(){
          $('.orarend-block').css("height", "auto");
          $('.orarend').addClass("loaded");
        });
        setTimeout(function(){
          smoothScrollTo($(scrollTo), 100);
        }, 10);
      }
    });
  }

  // Timetable send reminder function
  // ---------------------------------------------------------------------------
  var locationOrigHTML;
  var buttonContOrigHTML;
  var token;
  var timeTableItemContent;

  $("body").on('click', '.timetable-reminder', function(){
    // Get the timetable-item id
    var timetableItemId = $(this).attr('data-id');
    token = $(this).attr('data-token');
    timeTableItemContent = $(this).parent().find("input[name='timetableItemData']").val();
    // change opacity on all rows
    $('.data-row').addClass('hidden-row');
    // disable all buttons
    $('.timetable-reminder').attr('disabled', 'true');
    // activate selected row
    $(this).closest('tr').removeClass('hidden-row').addClass('selected-row');
    // clear button and location text
    // $(this).css('display', 'none');
    //create html snippets
    var regSnippet = '<form class="form-inline" action="" method="post" name="timeTableRegForm" id="timeTableRegForm">' +
                        '<input type="hidden" name="timeTableItemId" value="' + timetableItemId + '">' +
                        '<input type="hidden" name="_token" value="' + token + '">' +
                        '<input type="text" name="timeTableFullname" class="form-control" value="" placeholder="Név">' +
                        '<input type="text" name="timeTableEmail" class="form-control" value="" placeholder="E-mail cím">' +
                      '</form>';

    var cancelBtnSnippet = '<button  class="btn btn-yellow btn-xs timetable-reg">Küldés</button><br>' +
                            '<button class="btn btn-yellow btn-xs timetable-reg-cancel">Mégse</button>';

    // add html form snippet for registering
    locationOrigHTML = $(this).closest('.selected-row').find('td.location-container').html();
    buttonContOrigHTML = $(this).closest('.button-container').html();

    $(this).closest('.selected-row').find('td.location-container').html(regSnippet);
    $(this).closest('.button-container').html(cancelBtnSnippet);

    $("#timeTableRegForm").validate({
        onsubmit: false,
        rules: {
          timeTableFullname: {
            required: true,
            minlength: 5
          },
          timeTableEmail: {
            required: true,
            email: true
          }
        },
        messages: {
          timeTableFullname: {
            required: "Kérlek add meg a neved",
            minlength: "A név minimum 5 betű kell hogy legyen"
          },
          timeTableEmail: {
            required: "Kérlek add meg az email címed",
            email: "Az email cím hibásnak tűnik, kérlek ellenőrizd"
          }
        },
        errorPlacement: function(){
            return false;  // suppresses error message text
        },
        submitHandler: function(form) {
          return false;
        }
      });

  });

  // Handle send btn button
  // ---------------------------------------------------------------------------
  $("body").on('click', '.timetable-reg', function(){
    var self = this;
    //$("#timeTableRegForm").submit();
    //console.log('lets validate');
    if ($("#timeTableRegForm").valid()){

      $('.timetable-reg').prepend('<i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="font-size: 14px;"></i> ').attr('disabled', 'true');
      $('.timetable-reg-cancel').attr('disabled', 'true');

      // Send the message
      $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token
        }
      });
      $.post( "/mail/send/timetablecontact", {'form':$( "#timeTableRegForm" ).serialize(), 'timeTableItemContent':timeTableItemContent}, function(data) {
        var selectedBtnContainer = $(self).closest('.button-container');
        if (data.status == 1){
          $(self).closest('.selected-row').find('td.location-container').html(locationOrigHTML);
          $('.data-row').removeClass('hidden-row').removeClass('selected-row');
          $('.timetable-reminder').removeAttr('disabled');
          selectedBtnContainer.html(buttonContOrigHTML);
          selectedBtnContainer.find('.timetable-reminder').html(data.payload).addClass("sent");
          $('.sent').attr('disabled', 'true');
        }
        if (data.status == 0){
          selectedBtnContainer.html('<div class="alert alert-danger alert-dismissable"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a><strong>Hiba!</strong> '+data.payload+'</div>');
        }

      })
      .fail(function() {
        console.log( "API call failed!" );
      });

    }

  });



  // Handle cancel btn button
  // ---------------------------------------------------------------------------
  $("body").on('click', '.timetable-reg-cancel', function(){
    // console.log('cancel');
    // change opacity back
    $('.data-row').removeClass('hidden-row');
    // Add original data to the fields
    $(this).closest('.selected-row').find('td.location-container').html(locationOrigHTML);
    $(this).closest('tr').removeClass('selected-row');
    $(this).closest('.button-container').html(buttonContOrigHTML);
    // Enable all buttons
    $('.timetable-reminder').removeAttr('disabled');
  });

  // EndOf Timetable send reminder function
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------




}());